import React from 'react'
import PhotoCard from './PhotoCard'

export default function Pictures() {
  const [pictures, setPictures] = React.useState([])
  async function getPictures() {
    const response = await fetch('https://api.pepsi.pics/all')
    const data = await response.json()
    console.log(data)
    setPictures(data)
  }
  React.useEffect(() => {
    getPictures();
  }, [])
  return (
    <section id="everypicture">

        <div className="container px-10 py-5 mx-auto">
            <div className="flex flex-wrap justify-between -m-4" style={{flex: '1 0 21%'}}>
              {pictures === [] ? <p>Loading...</p> : pictures.map((picture) => (
                <PhotoCard key={picture.name} name={picture.name} seen={picture.seen} />
              ))}
            </div>
        </div>
    </section>
  )
}
