import React from 'react'

export default function Navbar() {
  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <a className="title-font font-medium text-white mb-4 md:mb-0" href="#about">
              <a href="#about" className="ml-3 text-xl">
                Pepsi's Album
              </a>
            </a>
            <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center text-base justify-center">
                <a href="https://pepsi.pics" className="mr-5 hover:text-white">Main site</a>
            </nav>
        
        </div>
    </header>
  )
}
