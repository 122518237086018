import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Pictures from './components/Pictures';
function App() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
      <Navbar />
      <Pictures />
      <Footer/>
    </main>
  );
}

export default App;
